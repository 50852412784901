<template>
  <page-main
    title="Target Company"
    nextText="Use your information to generate or review a cover letter"
    :displayBeta="true"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/CoverLetterReview')"
    @save="save"
  >
    <template v-slot:aside>
      <div>
        <ul>
          <p>Continuing where we left off on the previous page, this section is dedicated to your target company: the company where you want to get a job. </p>
          <p></p>
          <li>If you don’t have a target company and want to create a more general letter, you can leave this section blank.</li>
          <p></p>
          <li>Feel free to provide as much or as little information as you want, but more detail will help create a more personalized letter. </li>
          <p></p>
          <li>Be sure to double-check your spelling, especially of the target company’s name!</li>
        </ul>
      </div>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li><span class="font-weight-bold">Target Company: </span>Resumation.net</li>
              <li><span class="font-weight-bold">What does the company do?: </span>This company develops the resumes and other professional development services.</li>
              <li><span class="font-weight-bold">What is the name of your desired role? (Enter just one; leave blank if not applicable or you don’t know): </span>Principal Program Manager</li>
              <li><span class="font-weight-bold">What do you find interesting or attractive about working for this company or your desired role?(1 to 3 sentences): </span>The role allows me to operate strategically to support scaling of the org.</li>
              <li><span class="font-weight-bold">What do you hope to get out of working for this company? (1 to 3 sentences): </span>I want to solve problems – Resumation.net allows me the opportunity to do this at scale.</li>
              <li><span class="font-weight-bold">Describe how you want the cover letter to feel and sound: </span>I want it to sound professional but concise.</li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <span class="font-weight-bold">Tips</span>
        <ul>
          <li><span class="font-weight-bold">Tip #1: </span>If you don’t have a target company and want to create a more general letter, you can leave this section blank.</li>
          <li><span class="font-weight-bold">Tip #2: </span>Use information from job postings or the target company’s webpage to answer questions about the company.</li>
          <li><span class="font-weight-bold">Tip #3: </span>Feel free to use first-person pronouns in this section (e.g., I; we; us)</li>
        </ul>
      </modal>
      <v-btn
        outlined
        class="mt-4"
        block
        @click="navToContactUs"
        >
        Any feedback? Contact us!
      </v-btn>
    </template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            label="What is the name of the company?"
            v-model="companyName"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            label="What does the company do? (One phrase or sentence)"
            v-model="companyDescription"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            label="What is the name of your desired role? (Enter just one; leave blank if not applicable or you don’t know)"
            v-model="positionName"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            label="What do you find interesting or attractive about working for this company or your desired role? (1 to 3 sentences)"
            hint="Use first-person like 'I', 'we', or 'us', and write in full sentences."
            persistent-hint
            v-model="roleInterest"
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            label="What do you hope to get out of working for this company? (1 to 3 sentences)"
            hint="Use first-person like 'I', 'we', or 'us', and write in full sentences."
            persistent-hint
            v-model="workingGoals"
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            label="Describe how you want the cover letter to feel and sound."
            v-model="coverLetterFeel"
          ></r-textarea>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="premiumBanner" persistent>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">Oops! Looks like you will need Resumation Plus to use Cover Letter Creator feature!
        </h4>
        <p class="mt-2">Upgrade to get unlimited use of the Optimize with AI features, hide/show parts of your resume
          in-tool (customizations), save and store multiple resumes, and much more for only $5 a month!</p>
        <h4 class="pt-2 text-center">Would you like to upgrade to Resumation Plus?</h4>
      </v-card-text>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap justify-center' : 'flex-wrap justify-end'">
        <v-btn color="primary" outlined text @click="navToPremiumPage()"> Yes - let’s do it! </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as log from "../../utility/logging/logger";


export default {
  name: "CoverLetterTargetCompany",
  data() {
    return {
      premiumBanner: false,
    };
  },
  methods: {
    async save() {
      try {
        await this.updateCoverLetter();
      } catch (e) {
        log.logError("Error saving cover letter");
      }
    },
    nav(routerPath) {
      // Navigate to the next page
      this.$router.push({ path: routerPath });
    },
    navToPremiumPage() {
      this.dialog = false;
      this.$router.push({ path: '/Upgrade' });
    },
    navToContactUs() {
      window.open('https://www.resumation.net/contact', '_blank');
    },
    ...mapActions("activeCoverLetter", ["updateCoverLetter"]),
    ...mapActions("auth", ["refreshPremium"]),
  },
  computed: {
    ...mapFields("activeCoverLetter", [
      "companyName",
      "companyDescription",
      "positionName",
      "roleInterest",
      "workingGoals",
      "isPageAllowed",
      "coverLetterFeel"
    ]),
    ...mapFields("coverLetter", [
      "coverLetterList"
    ]),
    ...mapFields("auth", {
        isPremium: "isPremium",
        remainingAiOptimizerAttempts: "remainingAiOptimizerAttempts",
      }),
  },
  async mounted() {
    if (!this.isPageAllowed)
    {
      this.nav('/SavedCoverLetters');
    }
    await this.refreshPremium();
    if (!this.isPremium ) 
    {
      this.dialog = false;
      this.premiumBanner = true;
    } 
  },
  beforeRouteLeave (to, from, next) {
    if(to.fullPath == "/CoverLetterReview")
    {
      this.isPageAllowed = true;
    } else {
      this.isPageAllowed = false;
    }
    next();
  },
};
</script>

